import React from 'react';
import PropTypes from 'prop-types';
import ChevronUp24 from '@andes/icons/ChevronUp24';

import { Button } from '@andes/button';

import Tracker from '../../Utils/tracker';

const Footer = ({ i18n, background, melidataPath, analyticsLabel }) => (
  <section className={`footer ${background}`}>
    <div className="footer--content">
      <h1 className="footer--content-title">
        {i18n.gettext(
          'Conviértete en un desarrollador partner de Mercado Pago',
        )}
      </h1>
      <Button
        className="icon-container"
        data-testid="scroll-top-button"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <ChevronUp24 className="icon" color="white" />
      </Button>
      <p className="footer--content-details">
        {i18n.gettext(
          'Ten acceso a tasas diferenciadas, soporte técnico y muchos otros beneficios exclusivos para ti y tus clientes. Es gratis.',
        )}
      </p>
      <Button
        className="footer--content-action"
        href="/developers/panel/developer-program"
        hierarchy="transparent"
        onClick={() => {
          Tracker.trackAnalyticsEvent({ label: analyticsLabel });
          Tracker.melidataTrack(melidataPath, { label: analyticsLabel });
        }}
      >
        {i18n.gettext('Quiero participar')}
      </Button>
    </div>
  </section>
);

Footer.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  background: PropTypes.string,
  melidataPath: PropTypes.string,
  analyticsLabel: PropTypes.string,
};

export default Footer;
