import React from 'react';
import PropTypes from 'prop-types';
import FeedbackPositive16 from '@andes/icons/FeedbackPositive16';

import { Button, ButtonText } from '@andes/button';

import ChevronRight16 from '@andes/icons/ChevronRight16';
import Tracker from '../../../../../Utils/tracker';

const CertificationCardContent = ({
  i18n,
  title,
  description,
  requirements,
  link,
  type,
  melidataPath,
}) => (
  <div className="certification-card-content">
    <h3 className="certifications--content-card-title">{title}</h3>
    <p className="certifications--content-card-description">{description}</p>
    <span className="line" />
    <h4 className="certifications--content-card-description bold">
      {i18n.gettext('Requisitos:')}
    </h4>
    {requirements.map((requirement) => (
      <div className="certifications--content-card-tag" key={requirement.id}>
        <FeedbackPositive16 color="#009EE3" />
        <p>{requirement.value}</p>
      </div>
    ))}
    <Button
      className="certifications--content-card-link"
      hierarchy="transparent"
      href={link}
      size="small"
      onClick={() => {
        Tracker.melidataTrack(melidataPath, {
          event: `ACQUISITION_LANDING_DEVELOPERS_${type}_CERTIFICATION_ACTION_CLICK`,
        });
        Tracker.trackAnalyticsEvent({
          label: `ACQUISITION_LANDING_DEVELOPERS_${type}_CERTIFICATION_ACTION_CLICK`,
        });
      }}
    >
      <ButtonText>{i18n.gettext('Saber más')}</ButtonText>
      <ChevronRight16 color="light-blue" stroke="light-blue" />
    </Button>
  </div>
);

CertificationCardContent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  link: PropTypes.string,
  type: PropTypes.string,
  melidataPath: PropTypes.string,
};

export default CertificationCardContent;
