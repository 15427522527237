/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-negated-condition */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonText } from '@andes/button';

import FAQItem from './components/FAQItem';
import Tracker from '../../../Utils/tracker';
import { Image } from 'nordic/image';

const FAQ = ({ i18n, questions, maxItems, melidataPath }) => {
  const [showingIndex, setShowingIndex] = useState({
    id: '0',
    description: null,
  });
  const [showAll, setShowAll] = useState(false);
  const [faqItems, setFaqItems] = useState(questions.slice(0, maxItems));

  const switcherClick = () => {
    Tracker.trackAnalyticsEvent({
      label: `ACQUISITION_LANDING_DEVELOPERS_FAQS_ACTION_CLICK_${
        !showAll ? 'OPENED' : 'CLOSED'
      }`,
    });
    Tracker.melidataTrack(melidataPath, {
      event: `ACQUISITION_LANDING_DEVELOPERS_FAQS_ACTION_CLICK_${
        !showAll ? 'OPENED' : 'CLOSED'
      }`,
    });
    setShowAll(!showAll);
  };

  useEffect(() => {
    setFaqItems(showAll ? questions : questions.slice(0, maxItems));
  }, [showAll]);

  useEffect(() => {
    if (showingIndex.description) {
      Tracker.trackAnalyticsEvent({
        label: `ACQUISITION_LANDING_DEVELOPERS_FAQ_${showingIndex.id}`,
      });
      Tracker.melidataTrack(melidataPath, {
        event: `ACQUISITION_LANDING_DEVELOPERS_FAQ_${showingIndex.id}`,
      });
    }
  }, [showingIndex.description]);

  return (
    <section className="faq" id="acquisition-landing-faq">
      <Image
        src="agencies/miscelanea.svg"
        alt="FAQ decoration"
        className="faq__img"
      />
      <div className="faq--title-container">
        <h2 className="faq--title">{i18n.gettext('¿Tienes dudas?')}</h2>
        <h2 className="faq--title">
          {i18n.gettext('Consulta nuestras preguntas frecuentes')}
        </h2>
      </div>

      {faqItems.map((question, index) => (
        <FAQItem
          question={question}
          index={index}
          showingIndex={showingIndex}
          setShowingIndex={setShowingIndex}
          key={question.id}
          isFirst={index === 0}
        />
      ))}
      <div className="faq--action">
        <Button hierarchy="transparent" onClick={switcherClick}>
          <ButtonText>
            {showAll ? i18n.gettext('Ver menos') : i18n.gettext('Ver más')}
          </ButtonText>
        </Button>
      </div>
    </section>
  );
};

FAQ.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  maxItems: PropTypes.number,
  melidataPath: PropTypes.string,
};

export default FAQ;
